
.illustration.boyGirl2 {
  left: 0;
  right: 0;
  position: absolute;
  transform: translate(0%, -100%);
}

.title.font-heading-30 {
  font-weight: 500;
  padding-top: calc(var(--grid-unit) * 3);
}

.to-kinside {
  margin-top: calc(var(--grid-unit) * 3);
}

.account-types {
  .type {
    height: calc(var(--grid-unit) * 9);
    padding: calc(var(--grid-unit) * 2) calc(var(--grid-unit) * 3);
    border-radius: calc(var(--grid-unit) * 3);
    display: flex;
    cursor: pointer;
    max-width: 344px;
    margin: auto;
    margin-top: calc(var(--grid-unit) * 3);
    border: 1px solid var(--gray-15);
    color: var(--gray-80);

      &--parent {
        &:hover {
          background-color: var(--periwinkle-0);
          border: 1px solid var(--periwinkle-15);
        }
      }

      &--provider {
        &:hover {
          background-color: var(--coral-0);
          border: 1px solid var(--coral-15);
        }
      }

    span {
      margin-left: var(--grid-unit);
      margin-top: var(--grid-unit);
      flex: 1;

      @media screen and (max-width: 360px) {
        margin-top: 0;
      }
    }

    @media screen and (max-width: 400px) {
      padding: calc(var(--grid-unit) * 2) calc(var(--grid-unit) * 2);
    }
  }
}

.circle {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  line-height: 2em;

  &--parent {
    background-color: var(--periwinkle-5);

    i {
      color: var(--periwinkle-30);
    }
  }

  &--provider {
    background-color: var(--coral-5);

    i {
      color: var(--coral-30);
    }
  }
}

.icon-arrow {
  color: var(--gray-40);
  margin-block: auto;
}
