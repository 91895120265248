
.btn-primary:disabled {
  background-color: var(--viridian-50) !important;
  border-color: var(--viridian-50) !important;
}

.btn-primary:hover {
  color: #fff;
  background-color: var(--viridian-50) !important;
  border-color: var(--viridian-50) !important;
}

.error-message {
  color: var(--rust-40);
}

.align-center {
  color: var(--gray-80);
}

.resend-verification {
  a {
    color: var(--viridian-50) !important;
    padding-left: var(--grid-unit);
    text-decoration: auto;
  }

  font-size: var(--font-size-10);
  line-height: calc(var(--grid-unit) * 3);
  margin-top: calc(var(--grid-unit) * 2);
}

.register {
  background: none !important;
}

.verify-account {
  font-weight: 400;
}
